import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Order } from '../../models/order';
import { InvoiceService } from '../../services/invoice.service';
import { PassOrderComponent } from '../pass-order/pass-order.component';

@Component({
  selector: 'app-ticket-order',
  templateUrl: './ticket-order.component.html',
  styleUrls: ['./ticket-order.component.scss'],
})
export class TicketOrderComponent{
  @Input() invoiceData: Order;

  constructor(
    private modalController: ModalController,
    private invoiceService: InvoiceService,
  ) { 
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async passOrder() {
    const orderId: string = this.invoiceData.id;
    const modal = await this.modalController.create({
      component: PassOrderComponent,
      cssClass: 'invoice-modal-css',
      showBackdrop: false,
      componentProps: {
        orderId: orderId
      }
    });

    await modal.present();
  }
}
