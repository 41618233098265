import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CallState, RegistrationState, SipCall } from '@ionic-native/sip-call/ngx';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastPosition, ToastService } from 'src/app/common/services/toast.service';
import { environment } from 'src/environments/environment';
import { CourierRoutes } from '../../courier.routes';
import { CallRoute } from './call.routes';
import { OutgoinCall } from './models/outgoin-call';

@Injectable({
    providedIn: 'root'
})
export class CallService {
    speakerEnabled: boolean = false;
    microphoneMuted: boolean = false;
    onRegistrationStateChanged: BehaviorSubject<RegistrationState> = new BehaviorSubject<RegistrationState>(RegistrationState.None);
    onCallStateChanged: Subject<CallState> = new Subject<CallState>();
    currentOutgoinCall: OutgoinCall;

    constructor(
        private sipCall: SipCall,
        private toastService: ToastService,
        private router: Router,
        private alertController: AlertController,
    ) {
    }

    // TODO: get sip call credentials depending on current kingslize branch
    initSipCall(): void {
        this.setupEventHandlers();
        this.sipCall.requestPermissions();
        this.sipCall.login(environment.sipCall.username, environment.sipCall.password, environment.sipCall.realm);
    }

    call(outgoinCall: OutgoinCall): void {
        this.showCallConfirmAlert()
            .then(()=>{
                this.currentOutgoinCall = outgoinCall;
                this.router.navigate([CourierRoutes.Call, CallRoute.Outgoing])
                    .then(() => {
                        this.sipCall.call(outgoinCall.callee.number, outgoinCall.caller.name);
                    })
            })
    }

    toggleSpeaker(): void {
        this.sipCall.setSpeakerEnabled(!this.speakerEnabled);
        this.speakerEnabled = !this.speakerEnabled;
    }

    toggleMute(): void {
        this.sipCall.setMicrophoneMuted(!this.microphoneMuted);
        this.microphoneMuted = !this.microphoneMuted;
    }

    hangup(): void {
        this.sipCall.hangup();
    }

    private async showCallConfirmAlert(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.alertController
                .create({
                    header: 'Ben je zeker dat je deze klant wil bellen?',
                    buttons: [
                        {
                            text: 'Annuleer',
                            role: 'cancel',
                            handler: () => reject(false),
                        },
                        {
                            text: 'Ja',
                            handler: () => resolve(true),
                        }
                    ]
                })
                .then(alert => {
                    alert.present();
                });
        });
    }

    private setupEventHandlers(): void {
        this.sipCall.onRegistrationStateChanged()
            .pipe(
                tap((regState) => this.onRegistrationStateChanged.next(regState)),
                tap((regState) => {
                    if (regState == RegistrationState.Failed) {
                        this.toastService.showErrorToast('Bellen is momenteel niet mogelijk', ToastPosition.Top);
                    }
                })
            ).subscribe();
        this.sipCall.onCallStateChanged()
            .pipe(
                tap((callState) => this.onCallStateChanged.next(callState)),
                tap((callState) => {
                    if (callState == CallState.Error) {
                        this.toastService.showErrorToast('Probleem met bellen probeer later opnieuw', ToastPosition.Top);
                    }
                    if (callState == CallState.Ended) {
                        this.currentOutgoinCall = null;
                        this.router.navigateByUrl('');
                    }
                })
            ).subscribe();
    }
}