import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppRoutes } from './app.routes';
import { AccountGuardService } from './common/guards/account.guard';
import { AuthGuardService } from './core/authentication/auth.guard';

const routes: Routes = [
  {
    path: AppRoutes.Start,
    loadChildren: () => import('./features/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: AppRoutes.Courier,
    loadChildren: () => import('./features/courier/courier.module').then(m => m.CourierModule),
    canActivate: [AuthGuardService, AccountGuardService]
  },
  {
    path: '', redirectTo: AppRoutes.Start, pathMatch: 'full'
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
