import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Courier } from '../models/courier';
import { CourierRepo } from '../repositories/courier.repo';

@Injectable({
  providedIn: 'root'
})
export class CourierService {

  constructor(
    private courierRepo: CourierRepo
  ) { }

  getActiveCouriers(): Observable<Courier[]>{
    return this.courierRepo.getActiveCouriers();
  }

  passOrderToCourier(courierId: string, orderId: string){
    return this.courierRepo.pushOrder(courierId,orderId);
  }
}
