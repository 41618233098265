import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Account } from '../common/models/account';
import { CourierRepo } from '../common/repositories/courier.repo';
import { AuthAction } from './authentication/auth-action';
import { AuthService } from './authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  account: Account;

  constructor(
    private courierRepo: CourierRepo,
    private authService: AuthService,
  ) {
    this.authService.onAuthChanged.subscribe((authAction) =>{
        if(authAction === AuthAction.Logout) {
          this.account = null;
        }
        if(authAction === AuthAction.Login){
          this.getAccount();
        }
    })
   }

  getName(): string | null {
    return this.account?.name;
  };

  isAccountActive(): boolean {
    return this.account?.active;
  }

  isReadyForDelivery(): boolean {
    return this.account?.readyForDelivery;
  }

  getAccount(forceReload: boolean = false): Observable<Account> {
    if (this.account && !forceReload) { return of(this.account); }

    return this.courierRepo.getAccount()
      .pipe(tap((account) => {
        this.account = account;
      }));
  }

  updateVehicleInUse(vehicleId: number): Observable<any> {
    return this.courierRepo.getAccount().pipe(
      switchMap((account) => {
        return this.courierRepo.updateAccount({
          name: account.name,
          email: account.email,
          telephoneNumber: account.telephoneNumber,
          selectedVehicleId: vehicleId
        })
      })
    )
  }

  goInActive(): Observable<void> {
    if (!this.isAccountActive()) { return of(undefined); }

    return this.courierRepo.deActivateCourier()
      .pipe(tap(() => {
        this.account.active = false;
      }));
  }

  goActive(code: string): Observable<void> {
    return this.courierRepo.activateCourier(code)
      .pipe(tap(() => {
        this.account.active = true;
      }));
  }

  setReadyForDelivery(): Observable<void> {
    return this.courierRepo.postReadyForDelivery()
      .pipe(tap(() => {
        this.account.readyForDelivery = true;
      }));
  }
}