import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this.authenticationService.getAuthorizationToken();
        let request = req.clone({
            headers: req.headers.set('X-API-KEY', environment.apiKey)
        });

        // If request is login request -> code below is not needed -> handle req
        if (req.url === `${environment.apiBaseUrl}/courier/login`) { next.handle(request); }

        if (authToken) {
            request = request.clone({
                headers: request.headers.set('X-AUTH-TOKEN', authToken)
            });
        }

        return next.handle(request)
            .pipe(tap(() => { },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status !== 401) {
                            return;
                        }
                        this.authenticationService.logout(false);
                    }
                }));
    }
}
