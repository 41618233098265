import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastPosition, ToastService } from '../common/services/toast.service';
import { AuthService } from './authentication/auth.service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthService,
        private toastService: ToastService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req)
            .pipe(catchError((error) => {
                if (error.status === 401) {
                    this.authenticationService.logout(false);
                }
                this.showErrorToast();
                return throwError(error);
            }))
    }

    private async showErrorToast(): Promise<void> {
        await this.toastService.showErrorToast('Er is iets misgelopen met het ophalen van gegevens', ToastPosition.Top);
    }
}
