import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
})
export class InputCodeComponent {
  code: string;

  constructor(
    private modalController: ModalController,
  ) { }

  enteredCode(){
    this.modalController.dismiss({
      'code': this.code
    });
  }
}
