import { Type } from 'class-transformer';
import { CountdownConfig } from 'ngx-countdown';
import { PinColor } from './colors-pins';

export class Client {
    name: string;
    telephoneNumber: string;
    email: string;
    notas: any[];
    street: string;
    streetNr: string;
    bus?: any;
    zipCode: string;
    city: string;
    lat: string;
    lng: string;
    distanceInKm: number;
    travelTimeInMinutes: string;
    oneTimeExtraInfo?: any;
    extraInfo?: any;
    internalInfo?: any;

    public getAddress(): string {
        return `${this.street} ${this.streetNr}, ${this.zipCode} ${this.city}`;
    }
}

export class OrderLine {
    count: string;
    article: string;
    size: string;
    price: string;
}

export class OrderDetail {
    publicReference: string;
    orderLines: OrderLine[];
    totalPrice: string;
    paymentType: PaymentMethod;
    paymentComplete: boolean;
    @Type(() => Date) 
    timedOrderDate: Date;
    timedOrder: boolean;
    notas: any[];
}

export class OrderDto {
    id: string;
    @Type(() => Client)
    client: Client;
    @Type(() => OrderDetail)
    order: OrderDetail;

    getCountdownConfig(): CountdownConfig | null {
        if (!this.order.timedOrder) return null;
        return {
            stopTime: this.order.timedOrderDate.getTime()
        }
    }
}

export class Order extends OrderDto {
    isInDelivery = false;
    pinColor: PinColor = null;
}

export class ExtraInfo {
    orderId: string;
    internalInfo: string;
    extraInfo: string;
    oneTimeExtraInfo: string;
}

export enum PaymentMethod {
    Cash = 'Cash',
    Card = 'Bancontact',
    Payconiq = 'Payconiq'
}
