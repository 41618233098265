import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Courier } from '../../models/courier';
import { CourierService } from '../../services/courier.service';

@Component({
  selector: 'app-pass-order',
  templateUrl: './pass-order.component.html',
  styleUrls: ['./pass-order.component.scss'],
})
export class PassOrderComponent {
  @Input() orderId: string;
  activeCouriers: Courier[];

  constructor(
    private modalController: ModalController,
    private courierService: CourierService,
    ) {
      this.courierService.getActiveCouriers().subscribe(couriers => this.activeCouriers = couriers);
     }

  closeModal() {
    this.modalController.dismiss();
  }

  passTo(courierId: string){
    console.log(courierId);
    console.log(this.orderId);
    this.courierService.passOrderToCourier(courierId, this.orderId).subscribe();
  }

}
