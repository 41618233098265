import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import OneSignal from 'onesignal-cordova-plugin';
import { AuthService } from './authentication/auth.service';
import { AuthAction } from './authentication/auth-action';
import { AccountService } from './account.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private authService: AuthService,
        private accountService: AccountService,
    ) {
    }

    initNotifications(): void {
        this.authService.onAuthChanged.subscribe((authAction) => {
            if (authAction === AuthAction.Login) {
                this.accountService.getAccount()
                    .subscribe((account)=>{
                        OneSignal.setExternalUserId(`courier_${account.id}`);
                    });
            } else if (authAction === AuthAction.Logout) {
                OneSignal.removeExternalUserId();
            }
        });
        OneSignal.setAppId(environment.oneSignalAppId);
    }
}