// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiKey: '34c36c85-48c6-45ac-a61f-e2b129bb279f',
  apiBaseUrl: 'https://staging.kingslizepizza.be/api',
  // apiBaseUrl: 'https://staging:kspizz@staging.kingslizepizza.be/api',
  googleMapsApiKey: 'AIzaSyDEbSAbT4TgHJeXahM8gA6L_UjQLrVa_CM',
  oneSignalAppId: '03628ae2-2d12-4e55-b3a7-e22733b134f7',
  mapboxKey: 'pk.eyJ1Ijoib3Vzc2llMDAiLCJhIjoiY2w0ZnU0MzZ2MDNsNjNqbThpZnNoemY2cSJ9.qGYBkueC58wXq3rbcJRsrA',
  sipCall: {
    username : "kingslizepizza.t5vb2imb",
    password : "BKZqhSbmAgRYeuv8npd",
    realm : "kingslizepizza.allocloud.com:10060;transport=udp",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
