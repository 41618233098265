import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExtraInfo, Order } from 'src/app/common/models/order';
import { CourierRepo } from '../repositories/courier.repo';

@Injectable({
  providedIn: 'root'
})

export class OrderService {
  orders: Order[] = [];
  finishedOrders: Order[] = [];
  onOrdersChange: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>(this.orders);
  onFinishedOrdersChange: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>(this.finishedOrders);
  orderSequence: string[];

  constructor(
    private courierRepo: CourierRepo,
  ) {
  }

  updateOrders(orders: Order[]): void {
    this.orders = orders;
    this.orderSequence = this.orders.map(order => order.id);
    this.onOrdersChange.next(orders);
  }

  hasOrdersInDelivery(): boolean {
    return this.orders.filter(x => x.isInDelivery === true).length > 0;
  }

  startDelivery(orderId: string): Observable<any> {
    return this.courierRepo.startDelivery(orderId);
  }

  endDelivery(orderId: string): Observable<any> {
    return this.courierRepo.endDelivery(orderId);
  }

  addNote(extraInfo: ExtraInfo) {
    return this.courierRepo.updateExtraInfo(extraInfo);
  }

  refreshOrders(): Observable<Order[]> {
    return forkJoin([
      this.courierRepo.ordersInDelivery(),
      this.courierRepo.ordersReadyForDelivery()
    ]).pipe(
      map(([orderInDelivery, ordersReadyForDelivery]) => [...orderInDelivery, ...ordersReadyForDelivery]),
      map((orders: Order[]) => this.orderBySequence(orders)),
      tap(_ => this.getFinishedOrders()),
      tap(orders => this.updateOrders(orders)),
    );
  }

  private getFinishedOrders(): void {
    this.courierRepo.ordersFinished().subscribe(orders => {
      this.finishedOrders = orders
      this.onFinishedOrdersChange.next(orders);
    });
  }

  private orderBySequence(orders: Order[]): Order[] {
    // if there is an ordersequence present order by that sequence
    if (this.orderSequence) {
      orders.sort((a, b) =>
        this.orderSequence.indexOf(a.id) - this.orderSequence.indexOf(b.id)
      );
    } else {  // else order by distance in km
      orders.sort((a, b) => a.client.distanceInKm - b.client.distanceInKm);
    }
    // always put order that is in delivery first
    const orderInDeliveryIndex = orders.findIndex(order => order.isInDelivery);
    if (orderInDeliveryIndex != -1) {
      const orderInDelivery = orders[orderInDeliveryIndex];
      orders.splice(orderInDeliveryIndex, 1);
      orders = [orderInDelivery, ...orders];
    }
    return orders;
  }
}
