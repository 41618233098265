import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export type LoginResult = {
    token: string;
};

@Injectable({ providedIn: 'root' })
export class AuthRepo {

    constructor(
        private http: HttpClient
    ) {
    }

    login(code: string): Observable<LoginResult> {
        return this.http.post<LoginResult>(`${environment.apiBaseUrl}/courier/login-with-code`, { code })
            .pipe(shareReplay());
    }

    logout(): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/logout`, {});
    }
}
