import { Component } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { AppRoutes } from './app.routes';
import { OrderService } from './common/services/order.service';
import { AccountService } from './core/account.service';
import { AuthService } from './core/authentication/auth.service';
import { NotificationService } from './core/notification.service';
import { CallService } from './features/courier/components/call/call.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  readonly AppRoutes: typeof AppRoutes = AppRoutes;

  constructor(
    public accountService: AccountService,
    private menuController: MenuController,
    private authService: AuthService,
    notificationService: NotificationService,
    orderService: OrderService,
    callService: CallService,
    platform: Platform,
  ) {
    platform.ready().then(()=> {
      if(platform.is('cordova')) {
        notificationService.initNotifications();
        callService.initSipCall();
      }
    })
    orderService.refreshOrders().subscribe();
  }

  async closeMenu(): Promise<void> {
    await this.menuController.close();
  }

  logout(): void {
    this.authService.logout();
    this.closeMenu();
  }
}
