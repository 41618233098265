import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Order } from '../../models/order';
import { CourierRepo } from '../../repositories/courier.repo';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataState } from 'src/app/shared/data-state/data-state';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  templateUrl: './payment-qr-code.component.html',
  styleUrls: ['./payment-qr-code.component.scss'],
})
export class PaymentQrCodeComponent implements OnInit {
  readonly DataState: typeof DataState = DataState;

  @Input() invoiceData: Order;
  imagePath: SafeResourceUrl;
  dataState: DataState;

  constructor(
    private modalController: ModalController,
    private courierRepo: CourierRepo,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.dataState = DataState.Loading;
    this.courierRepo.getOrderPayconiqQr(this.invoiceData.id)
      .pipe(
        catchError(() => {
          this.dataState = DataState.Error;
          return EMPTY;
        }),
      )
      .subscribe(imageSt => {
        this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + imageSt);
        this.dataState = DataState.None;
      })
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
