import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AccountService } from 'src/app/core/account.service';

@Injectable()
export class AccountGuardService implements CanActivate {
    constructor(
        private accountService: AccountService,
    ) { }

    canActivate(): boolean {
        if (!this.accountService.account) {
            this.accountService.getAccount()
                .subscribe(() => true);
        }
        return true;
    }
}
