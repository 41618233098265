import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LoadingStateComponent } from './components/loading-state/loading-state.component';


const COMPONENTS = [
    LoadingStateComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: COMPONENTS,
})
export class DataStateModule { }
