import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/authentication/auth.interceptor';
import { SharedModule } from './shared/shared.module';

import { AuthGuardService } from './core/authentication/auth.guard';
import { InvoiceService } from './common/services/invoice.service';
import { OrderService } from './common/services/order.service';
import { AccountGuardService } from './common/guards/account.guard';
import { AccountService } from './core/account.service';
import { HttpErrorInterceptor } from './core/http-error.interceptor';
import { SipCall } from '@ionic-native/sip-call/ngx';

const PROVIDERS =
  [
    AuthGuardService,
    AccountGuardService,
    InvoiceService,
    OrderService,
    AccountService,
    SipCall,
  ];

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
  ],
  providers: [
    PROVIDERS,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
