import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../models/order';
import { CourierRepo } from '../repositories/courier.repo';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(
    private courierRepo: CourierRepo,
    ) { }

  sendEmail(orderId: string, emailAddress: string): void {
    this.courierRepo.sendEmail(orderId, emailAddress).subscribe();
  }
}
