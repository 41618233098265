import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading-state',
    templateUrl: 'loading-state.component.html',
    styleUrls: ['loading-state.component.scss'],
})

export class LoadingStateComponent {
}
