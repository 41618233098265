import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

export enum ToastPosition {
    Top = 'top',
    Middle = 'middle',
    Bottom = 'bottom',
}

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(
        private toastController: ToastController,
    ) { }

    async showErrorToast(message : string, position : ToastPosition, duration: number = 3000): Promise<void> {
        const toast = await this.toastController.create({
            position: position,
            color: "danger",
            message: message,
            duration: duration
        });
        toast.present();
    }

    async showWarningToast(message : string, position : ToastPosition, duration: number = 3000): Promise<void> {
        const toast = await this.toastController.create({
            position: position,
            color: "warning",
            message: message,
            duration: duration
        });
        toast.present();
    }

    async showSuccessToast(message : string, position : ToastPosition, duration: number = 3000): Promise<void> {
        const toast = await this.toastController.create({
            position: position,
            color: "success",
            message: message,
            duration: duration
        });
        toast.present();
    }
}
