import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { InputCodeComponent } from '../common/modals/input-code/input-code.component';
import { NotesComponent } from '../common/modals/notes/notes.component';
import { PassOrderComponent } from '../common/modals/pass-order/pass-order.component';
import { PaymentQrCodeComponent } from '../common/modals/payment-qr-code/payment-qr-code.component';
import { TicketOrderComponent } from '../common/modals/ticket-order/ticket-order.component';
import { DataStateModule } from './data-state/data-state.module';

const COMPONENTS = [
    TicketOrderComponent,
    NotesComponent,
    PaymentQrCodeComponent,
    PassOrderComponent,
    InputCodeComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        DataStateModule,
    ],
    exports: [
        ...COMPONENTS,
        DataStateModule
    ],
})
export class SharedModule { }
