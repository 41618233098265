import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AppRoutes } from 'src/app/app.routes';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public authService: AuthService, public router: Router) { }
    canActivate(): boolean {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate([AppRoutes.Start]);
            return false;
        }
        return true;
    }
}