import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonTextarea, ModalController } from '@ionic/angular';
import { ExtraInfo } from '../../models/order';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NotesComponent {
  @Input() extraInfo: ExtraInfo;
  @ViewChild('extraInfoTextArea', { static: false }) extraInfoTextArea: IonTextarea;

  constructor(
    private modalController: ModalController,
    private orderService: OrderService,
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.extraInfoTextArea.setFocus();
    }, 500);
  }

  save() {
    this.orderService.addNote(this.extraInfo)
      .subscribe(() => {
        this.modalController.dismiss({
          'extraInfo': this.extraInfo.extraInfo,
          'oneTimeExtraInfo': this.extraInfo.oneTimeExtraInfo,
        });
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
