import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExtraInfo, Order, OrderDto } from '../models/order';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Account, UpdateAccount } from '../models/account';
import { PinColors } from '../models/colors-pins';
import { SmsMessage } from '../models/sms';
import { Vehicle } from '../models/vehicle';
import { Courier } from '../models/courier';

@Injectable({ providedIn: 'root' })
export class CourierRepo {

    constructor(
        private http: HttpClient
    ) {
    }

    sendEmail(orderId: string, emailAddress: string): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/email-ticket`, { orderId, emailAddress });
    }

    ordersReadyForDelivery(): Observable<Order[]> {
        return this.http.get<OrderDto[]>(`${environment.apiBaseUrl}/courier/get-orders-ready-for-delivery`)
            .pipe(
                map((order) => {
                    const orders = plainToClass(Order, order);
                    orders.map((order, index) => {
                        order.pinColor = PinColors[index];
                        return order;
                    })
                    return orders;
                })
            );
    }

    ordersInDelivery(): Observable<Order[]> {
        return this.http.get<OrderDto[]>(`${environment.apiBaseUrl}/courier/get-orders-in-delivery`)
            .pipe(
                map((order) => {
                    const orders = plainToClass(Order, order);
                    orders.map(x => {
                        x.isInDelivery = true;
                        return x;
                    });
                    return orders;
                })
            );
    }

    ordersFinished(): Observable<Order[]> {
        return this.http.get<OrderDto[]>(`${environment.apiBaseUrl}/courier/get-orders-finished`)
            .pipe(map((order) =>  plainToClass(Order, order)));
    }

    getOrderPayconiqQr(orderId: string): Observable<string> {
        return this.http.get<{ image: string }>(`${environment.apiBaseUrl}/courier/get-order-payconiq-qr/${orderId}`)
            .pipe(map(result => result.image));
    }

    startDelivery(orderId: string): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/start-delivery`, { orderId });
    }

    endDelivery(orderId: string): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/end-delivery`, { orderId });
    }

    activateCourier(code: string): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/activate`, { code });
    }

    deActivateCourier(): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/deactivate`, {});
    }

    getAccount(): Observable<Account> {
        return this.http.get<Account>(`${environment.apiBaseUrl}/courier/get-account`);
    }

    sendSmsMessage(smsMessage: SmsMessage): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/send-sms`, smsMessage);
    }

    updateAccount(updateAccount: UpdateAccount): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/update-account`, updateAccount);
    }

    updateExtraInfo(extraInfo: ExtraInfo): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/update-extra-info`, extraInfo);
    }

    getVehicles(): Observable<Vehicle[]> {
        return this.http.get<Vehicle[]>(`${environment.apiBaseUrl}/courier/get-vehicles`);
    }

    postReadyForDelivery(): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/ready-for-delivery`, {});
    }

    getActiveCouriers(): Observable<Courier[]> {
        return this.http.get<Courier[]>(`${environment.apiBaseUrl}/courier/get-couriers`)
            .pipe(
                map((couriers) => couriers.filter((courier) => courier.readyForDelivery))
                );
    }

    pushOrder(courierId: string, orderId: string): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/courier/push-order`, { courierId, orderId });
    }
}
