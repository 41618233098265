import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app.routes';
import { AuthAction } from './auth-action';
import { AuthRepo } from './auth.repo';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private readonly AUTH_TOKEN_KEY: string = 'AUTH_TOKEN';
    onAuthChanged: Subject<AuthAction> = new Subject<AuthAction>();

    constructor(
        private authenticationRepo: AuthRepo,
        private router: Router,
    ) {
    }

    getAuthorizationToken(): string {
        return localStorage.getItem(this.AUTH_TOKEN_KEY);
    }

    isLoggedIn(): boolean {
        return !!this.getAuthorizationToken();
    }

    login(code: string): Observable<unknown> {
        console.log(code);
        return this.authenticationRepo.login(code)
            .pipe(
                tap((loginResult) => this.setSession(loginResult.token)),
                tap(() => this.onAuthChanged.next(AuthAction.Login )),
                mapTo(undefined),
            );
    }

    logout(callServer: boolean = true) {
        if (callServer) {
            this.authenticationRepo.logout()
                .pipe(tap(() => this.onAuthChanged.next(AuthAction.Logout )))
                .subscribe();
        }
        localStorage.removeItem(this.AUTH_TOKEN_KEY);
        this.router.navigate([AppRoutes.Start]);
    }

    private setSession(token: string): void {
        localStorage.setItem(this.AUTH_TOKEN_KEY, token);
    }
}
